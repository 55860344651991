import { render, staticRenderFns } from "./branding.vue?vue&type=template&id=19962c43&scoped=true&"
import script from "./branding.vue?vue&type=script&lang=js&"
export * from "./branding.vue?vue&type=script&lang=js&"
import style0 from "./branding.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19962c43",
  null
  
)

export default component.exports